
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('bsri_config.subsidy_type_setup') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col  xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('bsri_config.subsidy_type')"
                        label-for="email"
                        >
                        <v-select name="subsidy_type_id"
                        v-model="search.subsidy_type_id"
                        label="text"
                        :options= subsidyTypeList
                        />
                        </b-form-group>
                    </b-col>
                    <b-col >
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_config.subsidy_type_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
                            {{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 border" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                            <b-button :variant="data.item.status === 1 ? ' iq-bg-success border' : '  iq-bg-danger border'" size="sm" @click="remove(data.item)">
                                                <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subsidyTypeList, subsidyTypeStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form
  },
  data () {
    return {
      search: {
        subsidy_type_id: 0,
        subsidy_name: ''
      },
      rows: []
    }
  },
  computed: {
    subsidyTypeList: function () {
        const dataLlist = this.$store.state.incentiveGrant.commonObj.subsidyTypeList.filter(item => item.status === 1)
        return dataLlist.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_config.subsidy_type_entry') : this.$t('bsri_config.subsidy_type_modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_config.org_name'), class: 'text-left' },
          { label: this.$t('bsri_config.subsidy_type'), class: 'text-left' },
          { label: this.$t('bsri_config.subsidy_type_description'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'organization_bn' },
          { key: 'type_name_bn' },
          { key: 'description_bn' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'organization_en' },
          { key: 'type_name' },
          { key: 'description' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, subsidyTypeStatus, item, 'incentive_grant', 'subsidyTypeList')
    },
    loadData () {
      var subsidyTypeSearch = ''
      if (this.search.subsidy_type_id) {
          subsidyTypeSearch = this.search.subsidy_type_id.value
      }
      const params = Object.assign({}, this.search, { subsidy_type_id: subsidyTypeSearch, page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, subsidyTypeList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelationalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
        const orgList = this.$store.state.commonObj.organizationProfileList
         const listData = data.map(item => {
              const orgData = orgList.find(organization => organization.value === item.org_id)
              const orgObj = {
                organization_en: orgData !== undefined ? orgData.text_en : '',
                organization_bn: orgData !== undefined ? orgData.text_bn : ''
              }
              return Object.assign({}, item, orgObj)
          })
          return listData
    }
  }
}
</script>
